import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/rem';

  .InternalTransfer {
    &__export-buttons {
      display: flex;
      gap: 15px;
      justify-content: ${isRTL ? 'flex-start' : 'flex-end'};

      &--select {
        width: 150px;

        .BaseSelectField {
          margin-bottom: 0;
          &__label {
            font-size: rem(15px);
            line-height: rem(22px);
            font-weight: 500;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .Select__control {
          min-height: 100% !important;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .InternalTransfer {
      &__export-buttons--select {
        .BaseSelectField {
          .Select__control {
            border-color: ${colors.primary.hex} !important;
            &::after {
              border-color: ${colors.primary.hex};
            }
          }

          &__label {
            color: ${colors.primary.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
