import axios from 'axios';

const geonamesAPI = axios.create({ baseURL: '' });
const validateStatus = status => status < 500;

export const getGeonameByGID = gid => geonamesAPI.get(`/geonames/gid/${gid}/`);

export const getUsers = search => axios.get(`/backoffice/user/`, { params: { ...search } });

export const generateAndDownloadSumSubReport = userId =>
  axios.get(`/sumsub/${userId}/applicantReport/`, { responseType: 'blob' });
export const getContacts = search => axios.get(`/crm/contacts/list/`, { params: { ...search } });
export const getUserData = id => axios.get(`/backoffice/user/${id}/`, { validateStatus });
export const getUserOperationHistory = (id, pageSize, currentPage, firstMonth, secondMonth) =>
  axios.get(
    `backoffice/user/${id}/events/all/?page_size=${pageSize}&page=${currentPage}&from=${firstMonth}-01&to=${secondMonth}-01`
  );
export const getUserLoginHistory = (id, pageSize, currentPage, firstMonth, secondMonth) =>
  axios.get(
    `backoffice/user/${id}/events/login/?page_size=${pageSize}&page=${currentPage}&from=${firstMonth}-01&to=${secondMonth}-01`
  );
export const getUserKYC = userId => axios.get(`/backoffice/user/${userId}/change_kyc/`);
export const editUserKYC = (userId, payload) =>
  axios.put(
    `/backoffice/user/${userId}/change_kyc/`,
    {
      ...payload,
    },
    { validateStatus: status => status < 300 || status === 400 || status === 409 }
  );

export const verifyUser = id => axios.post(`/backoffice/user/${id}/verify/`);
export const rejectUser = (id, params) => axios.post(`/backoffice/user/${id}/reject/`, { ...params });
export const blockUser = (id, params) => axios.post(`/backoffice/user/${id}/block/`, { ...params });
export const unblockUser = id => axios.post(`/backoffice/user/${id}/unblock/`);
export const disableSupportMessages = (id, params) =>
  axios.post(`/backoffice/user/${id}/support_messages_block/`, { ...params });
export const enableSupportMessages = (id, params) =>
  axios.post(`/backoffice/user/${id}/support_messages_unblock/`, { ...params });
export const changeUserPassword = (id, password) => axios.post(`/backoffice/user/${id}/password_change/`, { password });
export const editUserInfo = (id, field, value) => axios.put(`/backoffice/user/${id}/edit_info/`, { field, value });
export const removeUserPhone = id => axios.put(`/backoffice/user/${id}/remove_phone/`);
export const getUserUtmHistory = (userId, search) =>
  axios.get(`/backoffice/user/${userId}/utm_history/`, { params: { ...search } });

export const getUserDocumentFile = (userId, documentId) =>
  axios.get(`/backoffice/user/${userId}/document/${documentId}/file/`, {
    responseType: 'blob',
  });
export const getUserDocumentList = userId => axios.get(`/backoffice/user/${userId}/document/list/`);
export const verifyUserDocument = (id, documentId) =>
  axios.post(`/backoffice/user/${id}/document/${documentId}/verify/`);
export const rejectUserDocument = (id, documentId, params) =>
  axios.post(`/backoffice/user/${id}/document/${documentId}/reject/`, { ...params });

export const uploadUserDocument = (id, file, isDeposit) => {
  const data = new FormData();
  data.append('file', file);
  const config = { headers: { 'content-type': 'multipart/form-data' }, validateStatus: status => status <= 403 };
  return isDeposit
    ? axios.post(`/payments/request/${id}/proof/upload/`, data, config)
    : axios.post(`/backoffice/withdraw/${id}/proof/upload/`, data, config);
};

export const getWithdrawalProof = id =>
  axios.get(`/payments/request/${id}/proof/`, { responseType: 'blob', validateStatus });

export const getUserAccount = (userId, accountId) =>
  axios.get(`/backoffice/user/${userId}/account/${accountId}/`, { validateStatus });
export const getUserAccounts = (userId, params) => axios.get(`/backoffice/user/${userId}/account/`, { params });
export const getUserAccountsPrefetch = (userId, params) =>
  axios.get(`/backoffice/user/${userId}/account_prefetch/`, { params });
export const blockUserAccount = (userId, accountId) =>
  axios.post(`/backoffice/user/${userId}/account/${accountId}/block/`);
export const unblockUserAccount = (userId, accountId) =>
  axios.post(`/backoffice/user/${userId}/account/${accountId}/unblock/`);
export const deleteUserAccount = (userId, accountId) =>
  axios.post(`/backoffice/user/${userId}/account/${accountId}/archive/`);
export const restoreUserAccount = (userId, accountId) =>
  axios.post(`/backoffice/user/${userId}/account/${accountId}/unarchive/`);
export const confirmUserEmail = userId => axios.post(`/backoffice/user/${userId}/toggle_email_confirmation/`);

export const getUserTotalPayments = userId => axios.get(`/backoffice/user/${userId}/payments_summary/`);
export const getUserPayments = (userId, search) =>
  axios.get(`/backoffice/user/${userId}/payments/`, { params: { ...search } });
export const getUserInternalTransfers = (userId, search) =>
  axios.get(`/backoffice/user/${userId}/internal_transfers/`, { params: { ...search } });

export const getUserBonus = (id, { bonusType, ...params }) =>
  axios.get(`/backoffice/user/${id}/bonuses/${bonusType === 'subscriptions' ? 'subscriptions' : 'records'}/`, {
    params,
  });
export const createUserBonus = (userId, payload) =>
  axios.post(`/backoffice/user/${userId}/bonuses/records/create/`, { ...payload });
export const withdrawUserBonus = (userId, bonusId, params) =>
  axios.post(
    `/backoffice/user/${userId}/bonuses/records/${bonusId}/charge/`,
    { ...params },
    { validateStatus: status => status < 300 || status === 409 }
  );
export const activateUserBonus = (userId, bonusId, params) =>
  axios.post(`/backoffice/user/${userId}/bonuses/${bonusId}/activate/`, { ...params });
export const cancelUserBonus = (userId, bonusId) =>
  axios.post(`/backoffice/user/${userId}/bonuses/subscriptions/${bonusId}/cancel/`);

export const getUserNotes = id => axios.get(`/backoffice/user/${id}/note/`);

export const createUserNote = (id, data) => axios.post(`/backoffice/user/${id}/note/`, data);
export const deleteUserNote = (id, noteId) => axios.delete(`/backoffice/user/${id}/note/${noteId}/`);
export const editUserNote = (id, noteId, data) => axios.put(`/backoffice/user/${id}/note/${noteId}/`, { ...data });

export const createDocument = (id, body) =>
  axios.post(`/backoffice/user/${id}/document/internal/upload/`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const removeUserNoteDocument = (userId, documentId) =>
  axios.delete(`/backoffice/user/${userId}/document/internal/${documentId}/`);

export const getUserNoteDocument = (userId, documentId) =>
  axios.get(`/backoffice/user/${userId}/document/internal/${documentId}/`, { responseType: 'blob' });

export const verifyPartner = id => axios.post(`/backoffice/partnership/${id}/verify/`);
export const rejectPartner = (id, params) => axios.post(`/backoffice/partnership/${id}/reject/`, { ...params });

export const getAllTags = () => axios.get(`/backoffice/tag/`);
export const getUserTags = id => axios.get(`/backoffice/user/${id}/tag/`);
export const setUserTag = (userId, tagId) => axios.post(`/backoffice/user/${userId}/tag/${tagId}/`);
export const deleteUserTag = (userId, tagId) => axios.delete(`/backoffice/user/${userId}/tag/${tagId}/`);

export const generateSecurityCode = userId => axios.post(`/backoffice/user/${userId}/regenerate_pin/`);

export const getUserPartnerAccounts = userId => axios.get(`/backoffice/partnership/systems/${userId}/accounts/`);
export const getPartnershipConditionsList = userId =>
  axios.get(`/backoffice/partnership/systems/${userId}/conditions_list/`);
export const createUserPartnerAccount = payload =>
  axios.post(`/backoffice/partnership/systems/account/create/`, payload);
export const editUserPartnerAccount = (accountId, payload) =>
  axios.post(`/backoffice/partnership/systems/${accountId}/set_conditions/`, payload);

export const getAvailablePartnerCodes = (userId, search) =>
  axios.get(`/backoffice/partnership/systems/${userId}/change_partner_account/?search=${search}`);
export const editUserPartnerCode = (user, partnerAccount) =>
  axios.post(`/backoffice/partnership/systems/${user}/change_partner_account/`, { partnerAccount });

export const removeUserPartnerCode = user =>
  axios.post(`/backoffice/partnership/systems/${user}/delete_partner_account/`);

export const editTradeAccountPartnerCode = (user, tradeAccountId, partnerCode) =>
  axios.post(`/backoffice/user/${user}/account/${tradeAccountId}/change_partner_code/`, { partnerCode });

export const removeTradeAccountUserPartnerCode = (user, tradeAccountId) =>
  axios.post(`/backoffice/user/${user}/account/${tradeAccountId}/change_partner_code/`);

export const getUserIssues = search => axios.get(`/backoffice/support/`, { params: { ...search } });

export const startImpersonate = id => axios.post(`/backoffice/impersonate/${id}/start/`);

export const getUserCards = userId => axios.get(`/backoffice/user/${userId}/payment_cards/list/`);

export const getCardPhoto = (userId, cardId, side) =>
  axios.get(`/backoffice/user/${userId}/payment_cards/${cardId}/photo/${side}/`, {
    responseType: 'blob',
  });

export const verifyUserCard = (userId, cardId, params) =>
  axios.post(`/backoffice/user/${userId}/payment_cards/${cardId}/verify/`, { ...params });
export const rejectUserCard = (userId, cardId, params) =>
  axios.post(`/backoffice/user/${userId}/payment_cards/${cardId}/reject/`, { ...params });
export const addUserCard = (userId, formData) =>
  axios.post(`/backoffice/user/${userId}/payment_cards/add/`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const getUserAvailableDeposits = userId => axios.get(`/backoffice/user/${userId}/bonuses/available_deposits/`);

export const getFreeClientsCount = () => axios.get(`/backoffice/managers/get_client/`);

export const getManagerClient = () => axios.post(`/backoffice/managers/get_client/`, null, { validateStatus: false });

export const getBackofficeManagerList = search => axios.get(`/backoffice/managers/list/?search=${search}`);

export const setUserManager = (userId, manager) =>
  axios.post(`/backoffice/user/${userId}/change_manager/`, { manager });

export const editUserStatus = payload => axios.patch(`/backoffice/statuses/set_status/`, { ...payload });

export const createUser = (email, firstName, lastName, phone) =>
  axios.post('/backoffice/user/create/', { email, firstName, lastName, phone });

export const getServerList = () => axios.get(`/backoffice/server/list/`);

export const getCallProvidersList = () => axios.get(`/backoffice/user/click2call/`);

export const makeACall = (userId, provider) => axios.post(`/backoffice/user/${userId}/call/`, { provider });

export const createUserAccount = (userId, login, server) =>
  axios.post(`/backoffice/user/${userId}/add_account/`, { login, server });

export const appointLpaStatus = params => axios.post(`/backoffice/lpa/`, { ...params });

export const revokeLpaStatus = id => axios.delete(`/backoffice/lpa/${id}`);

export const editLpaStatus = (id, params) => axios.put(`/backoffice/lpa/${id}/`, { ...params });

export const getLpaInfo = id => axios.get(`/backoffice/lpa/${id}/`, { validateStatus });

export const getLpas = search => axios.get(`/backoffice/lpa/`, { params: { ...search } });

export const getCurrencies = () => axios.get('/utils/currencies/');

export const getCountries = () => geonamesAPI.get('/geonames/country/');

export const initializeBackofficeUserImport = (params, config, platform) =>
  axios.post('/backoffice/user/user_import/', { ...params, platform }, config);

export const getBOUserImports = search => axios.get('/backoffice/user/user_import/list/', { params: { ...search } });

export const downloadBackofficeUserImport = (id, ext) =>
  axios.get(`backoffice/user/user_import/${id}/${ext}/ `, { responseType: 'blob' });

export const getUserStatuses = () => axios.get('/status/statuses/');

export const depositWalletBalance = (userId, amount) =>
  axios.post(`/backoffice/user/${userId}/wallet/deposit/`, { amount });
export const withdrawWalletBalance = (userId, amount) =>
  axios.post(`/backoffice/user/${userId}/wallet/withdraw/`, { amount });

export const toggleManualApprovalUser = (userId, isManual) =>
  axios.post(`/backoffice/user/${userId}/manual_withdraw_approval/`, { isManual });
export const getUserSumSubDocuments = userId => axios.get(`/backoffice/user/${userId}/document/sumsub/list/`);
export const getUserSumSubFile = ({ userId, inspectionId, imageId }) =>
  axios.get(`/sumsub/${userId}/document/${inspectionId}/${imageId}/`, { responseType: 'blob' });
